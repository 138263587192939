import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MonitoredArticleInterface} from '../../interfaces/monitored-articles/monitored-article.interface';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MatDatepicker} from '@angular/material/datepicker';
import {FormControl} from '@angular/forms';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {UserService} from '../../services/user/user.service';
import {ArticlesService} from '../../services/articles/articles.service';
import {DatePipe} from '@angular/common';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {ReplaySubject, Subject} from 'rxjs';
import {MediaQueryService} from '../../services/media-query/media-query.service';
import {MatSelect} from '@angular/material/select';
import {MonitoredArticleActivityInterface} from '../../interfaces/monitored-article-comments/monitored-article-activity.interface';
import {Utils} from '../../classes/Utils';
import {ReadingArticleModal} from '../modals/reading-article/reading-article.modal';
import {AlertController, ModalController} from '@ionic/angular';
import {MonitoringConfirmClotureModalComponent} from '../monitoring-confirm-cloture-modal/monitoring-confirm-cloture-modal.component';
import {CreateLeadModalComponent} from '../modals/create-lead-modal/create-lead-modal.component';
import {ColorType, IconType} from '../parts/fe-icon/fe-icon.component';
import {ModalService} from '../../services/modal/modal.service';
import {FeArticleModalComponent} from "../modals/fe-article-modal/fe-article-modal.component";
import {GlobalService} from '../../services/global/global.service';

@Component({
    selector: 'app-business-signals-monitoring',
    templateUrl: './business-signals-monitoring.component.html',
    styleUrls: ['./business-signals-monitoring.component.scss'],
})
export class BusinessSignalsMonitoringComponent implements OnInit {

    public companyToken: string;
    private customerToken: string;
    private currentCustomerToken: string;
    // liste ID des articles
    private listAllArticlesID: number[] = [];
    // liste ID des leads
    private listAllLeadsID: number[] = [];
    // liste ID articles et leads
    private listAllIDs: number[] = [];
    private listArticlesInClose: number[] = [];
    private listArticlesInProcess: number[] = [];
    private listArticlesInQualified: number[] = [];
    private listArticlesToProcess: number[] = [];
    public articleMonitoring: MonitoredArticleInterface;
    public currentArticleAttributes;
    public listAllPriorities: string[] = [];
    public listAllStatuses: string[] = [];

    public globalCountToProcess: number;
    public nbPrio1ToProcess: number;
    public nbPrio2ToProcess: number;
    public nbPrio3ToProcess: number;

    public globalCountInProcess: number;
    public nbPrio1InProcess: number;
    public nbPrio2InProcess: number;
    public nbPrio3InProcess: number;

    public globalCountInQualified: number;
    public nbPrio1InQualified: number;
    public nbPrio2InQualified: number;
    public nbPrio3InQualified: number;

    public globalCountClosed: number;
    public nbPrio1Closed: number;
    public nbPrio2Closed: number;
    public nbPrio3Closed: number;

    todoArticles: MonitoredArticleInterface[] = [];
    inProgressArticles: MonitoredArticleInterface[] = [];
    qualifiedArticles: MonitoredArticleInterface[] = [];
    closedArticles: MonitoredArticleInterface[] = [];
    archivedArticles: MonitoredArticleInterface[] = [];

    articles: ArticleInterface[] = [];

    public monitoringParams = {};

    priorityOrder = 'no';
    sortingOrder = 'no';

    public showArchivedArticles = false;
    public showArchivedOptionForm = new FormControl();

    public startDatePicker: Date;
    public endDatePicker: Date;
    public endDate: String = '';
    public endDateLabel: String = 'Date de fin';
    public startDateLabel: String = 'Date de début';
    public startDate: String = '';
    public sortByPriority: Boolean = null;
    public sortByAddDate: Boolean = null;
    public blockPosition: Boolean = false;
    public companyCustomers: { user_id: String, name: string }[] = [];
    public currentCustomer: { user_id: string, name: string };
    public isSupervisor: Boolean = false;
    public isVisualisation: Boolean = false;
    public showShareButton: Boolean;
    protected loadingArticle: boolean = false;

    collaboratorsForm = new FormControl();
    public collaboratorsMultiFilterCtrl: FormControl = new FormControl();
    protected collaborators: { idCollaborator: number, nomCollaborator: string }[];
    public filteredMultiCollaborators: ReplaySubject<{ idCollaborator: number, nomCollaborator: string }[]> = new ReplaySubject<{ idCollaborator: number, nomCollaborator: string }[]>(1);
    @ViewChild('multiSelect') multiSelect: MatSelect;
    protected _onDestroy = new Subject<void>();

    dropdownList = [];
    tabCollaboratorsSelected = [];

    @ViewChild('matFormFieldParent') matFormFieldParentElement: ElementRef;
    isInputFocused: boolean;
    protected menuIconAffichage: string = 'keyboard_arrow_down';
    protected menuOpenAffichage: boolean = false;
    protected menuIconDate: string = 'keyboard_arrow_down';
    protected menuOpenDate: boolean = false;
    protected menuIconKanban: string = 'keyboard_arrow_down';
    protected menuOpenKanban: boolean = false;
    protected displayModePriority: string = '';
    protected displayModeDate: string = '';
    protected displayAKanban: string = 'Visualiser un kanban';
    protected listStyle: string = 'no';

    constructor(private articleMonitoringService: ArticleMonitoringService,
                private userService: UserService,
                private articlesService: ArticlesService,
                public mediaQueryService: MediaQueryService,
                private alertController: AlertController,
                private modalController: ModalController,
                private datePipe: DatePipe,
                private globalService: GlobalService,
                private modalService: ModalService) {
    }

    /**
     * Tri des articles suivis par position
     * @param boxArticles
     */
    sortMonitoringArticlesByPos(boxArticles) {
        boxArticles.sort((a, b) => {
                if (a.article.monitoring.position > b.article.monitoring.position) {
                    return 1;
                }
                if (a.article.monitoring.position < b.article.monitoring.position) {
                    return -1;
                }
                return 0;
            }
        );
    }


    ngOnInit() {

        this.globalService.updateMenuLink('monitoring');

        this.userService.getSessionDatas()
            .subscribe(
                (sessionData: SessionInterface) => {
                    if (sessionData !== undefined) {
                        this.companyToken = sessionData.customerDetails.companyId;
                        this.customerToken = sessionData.customerDetails.user_id;
                        this.isSupervisor = sessionData.customerDetails.isSupervisor;
                        this.companyCustomers.push({'user_id': this.customerToken, 'name': 'Mon Kanban'});
                        if (sessionData.companyCustomers !== undefined && sessionData.companyCustomers.length > 0) {
                            this.showShareButton = true;
                            for (let i = 0; i < sessionData.companyCustomers.length; i++) {
                                const userid = sessionData.companyCustomers[i].user_id;
                                const name = sessionData.companyCustomers[i].name;
                                const email = sessionData.companyCustomers[i].email;
                                this.companyCustomers.push({'user_id': userid, 'name': name});
                            }
                        } else {
                            this.showShareButton = false;
                        }
                    }
                }
            );
        /**
         * Initialisation
         */
        this.initArticles(null, null, null, null, null, this.customerToken, false);
        /**
         * Redirection depuis lien externe
         */
            // setTimeout(callback => {
        let monitoringId, articleMonitoringId, customer, isLead;
        if (Utils.hasBranchParameters()) {
            monitoringId = Utils.branchParams['monitoringId'];
            articleMonitoringId = Utils.branchParams['monitoringArticleId'];
            Utils.branchParams = [];
        } else if (this.getParameterByName('monitoringId') !== undefined || this.getParameterByName('monitoringArticleId') !== undefined) {
            monitoringId = this.getParameterByName('monitoringId');
            articleMonitoringId = this.getParameterByName('monitoringArticleId');
            isLead = this.getParameterByName('isLead');
            customer = this.getParameterByName('token');
        }
        if (monitoringId !== undefined) {
            // Appel Modale des activités
            this.monitoringParams = {
                articleId: '' + articleMonitoringId + '',
                monitoredArticleId: '' + monitoringId + ''
            };
            let Query;
            if (isLead === undefined) {
                Query = {
                    articles: articleMonitoringId
                };
            } else {
                Query = {
                    articles: 0,
                    leads: articleMonitoringId
                };
            }

            this.articlesService.getArticlesContent(Query).subscribe(
                (articles) => {
                    this.openReadingModalFromExternalLink(articles[0], monitoringId);
                }
            );
        }

    }

    getParameterByName(name: any) {
        const url = window.location.href;
        name = name.replace(/[[]]/g, '\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return undefined;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace('/+/g', ' '));
    }

    public async openReadingModalFromExternalLink(article: ArticleInterface, monitoringId): Promise<void> {
        article.monitoring.monitoringId = monitoringId;
        this.modalService.showCustomModalWithActionOnClose(FeArticleModalComponent, {
            data: {
                article: article,
                monitoredArticle: article.monitoring,
                from: 'monitoring', // we came from monitoring. Not favorites or articles.
                fromMonitoring: true,
                fromComment: true
            }}, (data) => {
            this.initArticles(null, null, null, null, null, this.customerToken, false);
        });
    }

    public async openConfirmClotureModal(article: ArticleInterface, monitoringId, event: CdkDragDrop<MonitoredArticleInterface[] | null>, monitoredArticle: MonitoredArticleInterface): Promise<void> {
        this.modalService.showCustomModalWithActionOnClose(MonitoringConfirmClotureModalComponent, {
            data: {
                article: article,
                monitoredArticle: monitoredArticle,
                articleMonitoringId: monitoringId,
            }}, (data) => {
            if (data !== undefined) {
                monitoredArticle.article.monitoring.status = 'closed';
                monitoredArticle.status = 'closed';
                monitoredArticle.monitoringMetadata.contractStatus = data;
                this.suiteDrop(event, monitoredArticle, 'closed');
            }
        });
    }

    public initArticles(sortByPriority: Boolean, sortByAddDate: Boolean, addDate: String, endDate: String, showArchivedArticles: Boolean, customerId: string, isVisualisation: Boolean) {
        this.listAllArticlesID = [];
        this.listArticlesInClose = [];
        this.listArticlesInProcess = [];
        this.listArticlesInQualified = [];
        this.listArticlesToProcess = [];
        this.todoArticles = [];
        this.inProgressArticles = [];
        this.qualifiedArticles = [];
        this.closedArticles = [];
        this.listAllPriorities = [];
        this.listAllStatuses = [];
        this.listAllLeadsID = [];

        const getArticlesInit: any = {
            customer: customerId,
            isArchived: showArchivedArticles,
            monitoringPriority: null,
            sortByPriority: sortByPriority,
            sortByAddDate: sortByAddDate,
            monitoringStartDate: addDate,
            monitoringEndDate: endDate
        };
        this.loadingArticle = true;
        this.articleMonitoringService.getArticles(getArticlesInit).subscribe(
            articleMonitoring => {
                if (articleMonitoring.inClose.results !== undefined) {
                    for (let i = 0; i < articleMonitoring.inClose.results.length; i++) {
                        this.listAllArticlesID.push(articleMonitoring.inClose.results[i]);
                        this.listAllIDs.push(articleMonitoring.inClose.results[i]);
                        this.listArticlesInClose.push(articleMonitoring.inClose.results[i]);
                    }
                }
                if (articleMonitoring.inClose.leads !== undefined) {
                    for (let i = 0; i < articleMonitoring.inClose.leads.length; i++) {
                        this.listAllLeadsID.push(articleMonitoring.inClose.leads[i]);
                        this.listAllIDs.push(articleMonitoring.inClose.leads[i]);
                        this.listArticlesInClose.push(articleMonitoring.inClose.leads[i]);
                    }
                }
                if (articleMonitoring.inProcess.results !== undefined) {
                    for (let i = 0; i < articleMonitoring.inProcess.results.length; i++) {
                        this.listAllArticlesID.push(articleMonitoring.inProcess.results[i]);
                        this.listAllIDs.push(articleMonitoring.inProcess.results[i]);
                        this.listArticlesInProcess.push(articleMonitoring.inProcess.results[i]);
                    }
                }
                if (articleMonitoring.inProcess.leads !== undefined) {
                    for (let i = 0; i < articleMonitoring.inProcess.leads.length; i++) {
                        this.listAllLeadsID.push(articleMonitoring.inProcess.leads[i]);
                        this.listAllIDs.push(articleMonitoring.inProcess.leads[i]);
                        this.listArticlesInProcess.push(articleMonitoring.inProcess.leads[i]);
                    }
                }
                if (articleMonitoring.inQualified.results !== undefined) {
                    for (let i = 0; i < articleMonitoring.inQualified.results.length; i++) {
                        this.listAllArticlesID.push(articleMonitoring.inQualified.results[i]);
                        this.listAllIDs.push(articleMonitoring.inQualified.results[i]);
                        this.listArticlesInQualified.push(articleMonitoring.inQualified.results[i]);
                    }
                }
                if (articleMonitoring.inQualified.leads !== undefined) {
                    for (let i = 0; i < articleMonitoring.inQualified.leads.length; i++) {
                        this.listAllLeadsID.push(articleMonitoring.inQualified.leads[i]);
                        this.listAllIDs.push(articleMonitoring.inQualified.leads[i]);
                        this.listArticlesInQualified.push(articleMonitoring.inQualified.leads[i]);
                    }
                }
                if (articleMonitoring.toProcess.results !== undefined) {
                    for (let i = 0; i < articleMonitoring.toProcess.results.length; i++) {
                        this.listAllArticlesID.push(articleMonitoring.toProcess.results[i]);
                        this.listAllIDs.push(articleMonitoring.toProcess.results[i]);
                        this.listArticlesToProcess.push(articleMonitoring.toProcess.results[i]);
                    }
                }
                if (articleMonitoring.toProcess.leads !== undefined) {
                    for (let i = 0; i < articleMonitoring.toProcess.leads.length; i++) {
                        this.listAllLeadsID.push(articleMonitoring.toProcess.leads[i]);
                        this.listAllIDs.push(articleMonitoring.toProcess.leads[i]);
                        this.listArticlesToProcess.push(articleMonitoring.toProcess.leads[i]);
                    }
                }

                this.globalCountToProcess = this.getCount(articleMonitoring.toProcess.stats.globalCount);
                this.nbPrio1ToProcess = articleMonitoring.toProcess.stats.countPriorityHigh;
                this.nbPrio2ToProcess = articleMonitoring.toProcess.stats.countPriorityMedium;
                this.nbPrio3ToProcess = articleMonitoring.toProcess.stats.countPriorityLow;

                this.globalCountInProcess = this.getCount(articleMonitoring.inProcess.stats.globalCount);
                this.nbPrio1InProcess = articleMonitoring.inProcess.stats.countPriorityHigh;
                this.nbPrio2InProcess = articleMonitoring.inProcess.stats.countPriorityMedium;
                this.nbPrio3InProcess = articleMonitoring.inProcess.stats.countPriorityLow;

                this.globalCountInQualified = this.getCount(articleMonitoring.inQualified.stats.globalCount);
                this.nbPrio1InQualified = articleMonitoring.inQualified.stats.countPriorityHigh;
                this.nbPrio2InQualified = articleMonitoring.inQualified.stats.countPriorityMedium;
                this.nbPrio3InQualified = articleMonitoring.inQualified.stats.countPriorityLow;

                this.globalCountClosed = this.getCount(articleMonitoring.inClose.stats.globalCount);
                this.nbPrio1Closed = articleMonitoring.inClose.stats.countPriorityHigh;
                this.nbPrio2Closed = articleMonitoring.inClose.stats.countPriorityMedium;
                this.nbPrio3Closed = articleMonitoring.inClose.stats.countPriorityLow;

                for (let i = articleMonitoring.monitoringEnums.priority.length - 1; i >= 0; i--) {
                    this.listAllPriorities.push(articleMonitoring.monitoringEnums.priority[i].label);

                }
                for (let i = 0; i < articleMonitoring.monitoringEnums.contractStatus.length; i++) {
                    this.listAllStatuses.push(articleMonitoring.monitoringEnums.contractStatus[i].label);
                }

                const Query = {
                    articles: this.listAllArticlesID,
                    leads: this.listAllLeadsID,
                    criterions: undefined,
                    customer: customerId,
                    isMonitoring: true,
                    sortByPriority: sortByPriority,
                    sortByAddDate: sortByAddDate,
                    monitoringStartDate: addDate,
                    monitoringEndDate: endDate
                };

                this.articlesService.getArticlesContent(Query).subscribe(articles => {
                    let listArticles: any[] = [];
                    listArticles = articles;
                    for (let i = 0; i < listArticles.length; i++) {
                        const monitoringStartDate = this.datePipe.transform(articles[i].monitoring.monitoringStartDate, 'dd/MM/yyyy');
                        this.articleMonitoring = {
                            id: articles[i].id,
                            monitoringId: articles[i].monitoring.monitoringId,
                            title: articles[i].title,
                            monitoringStartDate: monitoringStartDate,
                            monitoringActivities: articles[i].monitoring.monitoringActivities,
                            monitoringAttachments: articles[i].monitoring.monitoringAttachments,
                            newAttachments: articles[i].monitoring.newAttachments,
                            newActivities: articles[i].monitoring.newActivities,
                            timeInToProcess: articles[i].monitoring.timeInToProcess,
                            timeInProcessing: articles[i].monitoring.timeInProcessing,
                            timeInQualified: articles[i].monitoring.timeInQualified,
                            timeInClosed: articles[i].monitoring.timeInClosed,
                            isArchived: null,
                            priority: null,
                            position: articles[i].monitoring.position,
                            status: articles[i].monitoring.status,
                            article: articles[i],
                            listStatusAvailable: this.listAllStatuses,
                            listPropertiesAvailable: this.listAllPriorities,
                            type: articles[i].type,
                            monitoringMetadata: {
                                amount: null,
                                amountFormated: null,
                                contractStatus: null,
                                closeDate: null,
                                reminderDate: null,
                                isUsualCustomer: null,
                                comment: null
                            },
                        };
                        if (articles[i].monitoring.monitoringAttachments !== undefined) {
                            this.articleMonitoring.monitoringAttachments = articles[i].monitoring.monitoringAttachments;
                        } else {
                            this.articleMonitoring.monitoringAttachments = [];
                        }
                        if (articles[i].monitoring.associatedCustomers !== undefined) {
                            this.articleMonitoring.associatedCustomers = articles[i].monitoring.associatedCustomers;
                        }
                        if (articles[i].monitoring.associatedCustomersRealName !== undefined) {
                            this.articleMonitoring.associatedCustomersRealName = articles[i].monitoring.associatedCustomersRealName;
                        }
                        if (articles[i].monitoring.priority !== undefined) {
                            this.articleMonitoring.priority = articles[i].monitoring.priority;
                        }
                        if (articles[i].monitoring.isArchived !== undefined) {
                            this.articleMonitoring.isArchived = articles[i].monitoring.isArchived;
                        }
                        if (articles[i].monitoring.monitoringMetadata !== undefined) {
                            let closeDate;
                            let reminderDate;
                            closeDate = this.datePipe.transform(articles[i].monitoring.monitoringMetadata.closeDate, 'dd/MM/yyyy');
                            reminderDate = this.datePipe.transform(articles[i].monitoring.monitoringMetadata.reminderDate, 'dd/MM/yyyy');
                            this.articleMonitoring.monitoringMetadata = {
                                amount: articles[i].monitoring.monitoringMetadata.amount,
                                amountFormated: articles[i].monitoring.monitoringMetadata.amountFormated,
                                contractStatus: articles[i].monitoring.monitoringMetadata.contractStatus,
                                closeDate: closeDate,
                                reminderDate: reminderDate,
                                comment: articles[i].monitoring.monitoringMetadata.comment,
                                isUsualCustomer: articles[i].monitoring.monitoringMetadata.isUsualCustomer,
                            };
                        }
                        this.articleMonitoring.isVisualisation = isVisualisation;
                        this.articleMonitoring.article.monitoring.isVisualisation = isVisualisation;


                        if (this.listArticlesInClose.includes(this.articleMonitoring.id)) {
                            this.articleMonitoring.statutProcessingTime = this.articleMonitoring.timeInClosed;
                            this.closedArticles.push(this.articleMonitoring);
                        }
                        if (this.listArticlesInProcess.includes(this.articleMonitoring.id)) {
                            this.articleMonitoring.statutProcessingTime = this.articleMonitoring.timeInProcessing;
                            this.inProgressArticles.push(this.articleMonitoring);
                        }
                        if (this.listArticlesInQualified.includes(this.articleMonitoring.id)) {
                            this.articleMonitoring.statutProcessingTime = this.articleMonitoring.timeInQualified;
                            this.qualifiedArticles.push(this.articleMonitoring);
                        }
                        if (this.listArticlesToProcess.includes(this.articleMonitoring.id)) {
                            this.articleMonitoring.statutProcessingTime = this.articleMonitoring.timeInToProcess;
                            this.todoArticles.push(this.articleMonitoring);
                        }
                    }
                    this.loadingArticle = false;
                });
            });

    }

    editMonitoredArticlePriority(list: string, monitoredArticle: { newPriority: string, oldPriority: string, articleId }): void {
        let newPriority;
        if (monitoredArticle.newPriority === 'low') {
            newPriority = 'Priorité C';
        }
        if (monitoredArticle.newPriority === 'medium') {
            newPriority = 'Priorité B';
        }
        if (monitoredArticle.newPriority === 'high') {
            newPriority = 'Priorité A';
        }
        if (this.todoArticles.find(article => article.id === monitoredArticle.articleId)) {
            this.todoArticles.find(article => article.id === monitoredArticle.articleId).priority = newPriority;
        }
        if (this.inProgressArticles.find(article => article.id === monitoredArticle.articleId)) {
            this.inProgressArticles.find(article => article.id === monitoredArticle.articleId).priority = newPriority;
        }
        if (this.qualifiedArticles.find(article => article.id === monitoredArticle.articleId)) {
            this.qualifiedArticles.find(article => article.id === monitoredArticle.articleId).priority = newPriority;
        }
        if (this.closedArticles.find(article => article.id === monitoredArticle.articleId)) {
            this.closedArticles.find(article => article.id === monitoredArticle.articleId).priority = newPriority;
        }
        if (list === 'todoArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio3ToProcess++;
            this.nbPrio1ToProcess--;
        }
        if (list === 'todoArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio3ToProcess++;
            this.nbPrio2ToProcess--;
        }

        if (list === 'todoArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio2ToProcess++;
            this.nbPrio1ToProcess--;
        }
        if (list === 'todoArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio2ToProcess++;
            this.nbPrio3ToProcess--;
        }

        if (list === 'todoArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio1ToProcess++;
            this.nbPrio2ToProcess--;
        }
        if (list === 'todoArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio1ToProcess++;
            this.nbPrio3ToProcess--;
        }


        if (list === 'inProgressArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio3InProcess++;
            this.nbPrio1InProcess--;
        }
        if (list === 'inProgressArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio3InProcess++;
            this.nbPrio2InProcess--;
        }

        if (list === 'inProgressArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio2InProcess++;
            this.nbPrio1InProcess--;
        }
        if (list === 'inProgressArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio2InProcess++;
            this.nbPrio3InProcess--;
        }

        if (list === 'inProgressArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio1InProcess++;
            this.nbPrio2InProcess--;
        }
        if (list === 'inProgressArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio1InProcess++;
            this.nbPrio3InProcess--;
        }


        if (list === 'qualifiedArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio3InQualified++;
            this.nbPrio1InQualified--;
        }
        if (list === 'qualifiedArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio3InQualified++;
            this.nbPrio2InQualified--;
        }

        if (list === 'qualifiedArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio2InQualified++;
            this.nbPrio1InQualified--;
        }
        if (list === 'qualifiedArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio2InQualified++;
            this.nbPrio3InQualified--;
        }

        if (list === 'qualifiedArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio1InQualified++;
            this.nbPrio2InQualified--;
        }
        if (list === 'qualifiedArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio1InQualified++;
            this.nbPrio3InQualified--;
        }


        if (list === 'closedArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio3Closed++;
            this.nbPrio1Closed--;
        }
        if (list === 'closedArticles' && monitoredArticle.newPriority === 'low' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio3Closed++;
            this.nbPrio2Closed--;
        }

        if (list === 'closedArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité A') {
            this.nbPrio2Closed++;
            this.nbPrio1Closed--;
        }
        if (list === 'closedArticles' && monitoredArticle.newPriority === 'medium' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio2Closed++;
            this.nbPrio3Closed--;
        }

        if (list === 'closedArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité B') {
            this.nbPrio1Closed++;
            this.nbPrio2Closed--;
        }
        if (list === 'closedArticles' && monitoredArticle.newPriority === 'high' && monitoredArticle.oldPriority === 'Priorité C') {
            this.nbPrio1Closed++;
            this.nbPrio3Closed--;
        }

    }

    editMonitoredArticleIsArchived(list: string, monitoredArticle: MonitoredArticleInterface) {
        if (list === 'todoArticles' && monitoredArticle.isArchived === true) {
            const index: number = this.todoArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.todoArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3ToProcess--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2ToProcess--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1ToProcess--;
            }
            this.globalCountToProcess--;
        }
        if (list === 'inProgressArticles' && monitoredArticle.isArchived === true) {
            const index: number = this.inProgressArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.inProgressArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3InProcess--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2InProcess--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1InProcess--;
            }
            this.globalCountInProcess--;
        }
        if (list === 'qualifiedArticles' && monitoredArticle.isArchived === true) {
            const index: number = this.qualifiedArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.qualifiedArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3InQualified--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2InQualified--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1InQualified--;
            }
            this.globalCountInQualified--;
        }
        if (list === 'closedArticles' && monitoredArticle.isArchived === true) {
            const index: number = this.closedArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.closedArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3Closed--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2Closed--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1Closed--;
            }
            this.globalCountClosed--;
        }
        if (list === 'todoArticles' && monitoredArticle.isArchived === false) {
            const index: number = this.todoArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.todoArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3ToProcess--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2ToProcess--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1ToProcess--;
            }
            this.globalCountToProcess--;
        }
        if (list === 'inProgressArticles' && monitoredArticle.isArchived === false) {
            const index: number = this.inProgressArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.inProgressArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3InProcess--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2InProcess--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1InProcess--;
            }
            this.globalCountInProcess--;
        }
        if (list === 'qualifiedArticles' && monitoredArticle.isArchived === false) {
            const index: number = this.qualifiedArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.qualifiedArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3InQualified--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2InQualified--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1InQualified--;
            }
            this.globalCountInQualified--;
        }
        if (list === 'closedArticles' && monitoredArticle.isArchived === false) {
            const index: number = this.closedArticles.indexOf(monitoredArticle);
            if (index !== null) {
                this.closedArticles.splice(index, 1);
            }
            if (monitoredArticle.priority === 'Priorité C') {
                this.nbPrio3Closed--;
            }
            if (monitoredArticle.priority === 'Priorité B') {
                this.nbPrio2Closed--;
            }
            if (monitoredArticle.priority === 'Priorité A') {
                this.nbPrio1Closed--;
            }
            this.globalCountClosed--;
        }
    }

    /**
     * Mise à jour des positions des articles suivi
     * @param articlesList
     */
    updateArticlesPositions(articlesList: MonitoredArticleInterface[]) {
        if (this.blockPosition === false) {
            const monitoringDataList = [];
            for (let i = 0; i < articlesList.length; i++) {
                articlesList[i].position = i;
                articlesList[i].article.monitoring.position = i;
                const monitoringData = {
                    articleId: articlesList[i].article.id,
                    customer: this.customerToken,
                    articleMonitoringPosition: i
                };
                monitoringDataList.push(monitoringData);
            }
            this.articleMonitoringService.updateMonitoringArticlesPositions(monitoringDataList).subscribe();
        }
    }

    suiteDrop(event: CdkDragDrop<MonitoredArticleInterface[] | null>, monitoredArticle: MonitoredArticleInterface, currentStatus: string) {

        /**
         * Cas déplacements intérieurs
         */
        if (event.previousContainer === event.container) {
            switch (event.container.id) {
                case 'todoBox':
                    moveItemInArray(this.todoArticles, event.previousIndex, event.currentIndex);
                    this.updateArticlesPositions(this.todoArticles);
                    break;
                case 'inProgressBox':
                    moveItemInArray(this.inProgressArticles, event.previousIndex, event.currentIndex);
                    this.updateArticlesPositions(this.inProgressArticles);
                    break;
                case 'qualifiedBox':
                    moveItemInArray(this.qualifiedArticles, event.previousIndex, event.currentIndex);
                    this.updateArticlesPositions(this.qualifiedArticles);
                    break;
                case 'closedBox':
                    moveItemInArray(this.closedArticles, event.previousIndex, event.currentIndex);
                    this.updateArticlesPositions(this.closedArticles);
                    break;
            }
            return;
        }

        /**
         * Cas déplacements entre status
         */
        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            /*event.currentIndex */ 0 /* put at top */
        );

        switch (event.container.id) {
            case 'todoBox':
                event.container.data[0].statutProcessingTime = event.container.data[0].timeInToProcess;
                break;
            case 'inProgressBox':
                event.container.data[0].statutProcessingTime = event.container.data[0].timeInProcessing;
                break;
            case 'qualifiedBox':
                event.container.data[0].statutProcessingTime = event.container.data[0].timeInQualified;
                break;
            case 'closedBox':
                event.container.data[0].statutProcessingTime = event.container.data[0].timeInClosed;
                break;
        }
        // Mise à jour des activités
        this.monitoringParams = {
            articleId: '' + monitoredArticle.id + '',
            articleMonitoringId: '' + monitoredArticle.monitoringId + ''
        };
        this.articleMonitoringService.getArticleActivities(this.monitoringParams).subscribe((data: Array<MonitoredArticleActivityInterface>) => {
            monitoredArticle.monitoringActivities = data;
        });
        // TODO Optimisation
        this.updateArticlesPositions(this.todoArticles);
        this.updateArticlesPositions(this.inProgressArticles);
        this.updateArticlesPositions(this.qualifiedArticles);
        this.updateArticlesPositions(this.closedArticles);

        this.currentArticleAttributes = {
            articleId: monitoredArticle.id,
            monitoringId: monitoredArticle.monitoringId,
            articleMonitoringPosition: monitoredArticle.article.monitoring.position,
            isArchived: monitoredArticle.isArchived,
            monitoringPriority: monitoredArticle.priority.valueOf(),
            monitoringStatus: currentStatus,
            timeInClosed: monitoredArticle.timeInClosed,
            timeInProcessing: monitoredArticle.timeInProcessing,
            timeInQualified: monitoredArticle.timeInQualified,
            timeInToProcess: monitoredArticle.timeInToProcess
        };
        const getArticlesInit: any = {
            customer: this.customerToken,
            isArchived: false,
            monitoringPriority: null
        };
        this.loadingArticle = true;
        this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe(object => {
            this.articleMonitoringService.getArticles(getArticlesInit).subscribe(
                articleMonitoring => {
                    this.globalCountToProcess = this.getCount(articleMonitoring.toProcess.stats.globalCount);
                    this.nbPrio1ToProcess = articleMonitoring.toProcess.stats.countPriorityHigh;
                    this.nbPrio2ToProcess = articleMonitoring.toProcess.stats.countPriorityMedium;
                    this.nbPrio3ToProcess = articleMonitoring.toProcess.stats.countPriorityLow;

                    this.globalCountInProcess = this.getCount(articleMonitoring.inProcess.stats.globalCount);
                    this.nbPrio1InProcess = articleMonitoring.inProcess.stats.countPriorityHigh;
                    this.nbPrio2InProcess = articleMonitoring.inProcess.stats.countPriorityMedium;
                    this.nbPrio3InProcess = articleMonitoring.inProcess.stats.countPriorityLow;

                    this.globalCountInQualified = this.getCount(articleMonitoring.inQualified.stats.globalCount);
                    this.nbPrio1InQualified = articleMonitoring.inQualified.stats.countPriorityHigh;
                    this.nbPrio2InQualified = articleMonitoring.inQualified.stats.countPriorityMedium;
                    this.nbPrio3InQualified = articleMonitoring.inQualified.stats.countPriorityLow;

                    this.globalCountClosed = this.getCount(articleMonitoring.inClose.stats.globalCount);
                    this.nbPrio1Closed = articleMonitoring.inClose.stats.countPriorityHigh;
                    this.nbPrio2Closed = articleMonitoring.inClose.stats.countPriorityMedium;
                    this.nbPrio3Closed = articleMonitoring.inClose.stats.countPriorityLow;
                    this.loadingArticle = false;
                });
        });
    }

    drop(event: CdkDragDrop<MonitoredArticleInterface[] | null>): void {

        if (!event.container.data || !event.previousContainer.data) {
            return;
        }

        let monitoredArticle: MonitoredArticleInterface;
        monitoredArticle = event.previousContainer.data[event.previousIndex];
        monitoredArticle.article.monitoring.position = event.currentIndex;

        // Update status
        let currentStatus;
        switch (event.container.id) {
            case 'todoBox':
                currentStatus = 'to_process';
                monitoredArticle.article.monitoring.status = currentStatus;
                monitoredArticle.status = currentStatus;
                this.suiteDrop(event, monitoredArticle, currentStatus);
                break;
            case 'inProgressBox':
                currentStatus = 'in_process';
                monitoredArticle.article.monitoring.status = currentStatus;
                monitoredArticle.status = currentStatus;
                this.suiteDrop(event, monitoredArticle, currentStatus);
                break;
            case 'qualifiedBox':
                currentStatus = 'qualified';
                monitoredArticle.article.monitoring.status = currentStatus;
                monitoredArticle.status = currentStatus;
                this.suiteDrop(event, monitoredArticle, currentStatus);
                break;
            case 'closedBox':
                if (monitoredArticle.article.monitoring.status !== 'closed') {
                    this.openConfirmClotureModal(monitoredArticle, monitoredArticle.monitoringId, event, monitoredArticle);
                }
                break;
        }
        // event.container.data[0].statut = currentStatus;

    }

    public updateStartDate(dateObject) {
        if (dateObject.value != null) {
            this.startDatePicker = new Date(dateObject.value);
            const year = this.startDatePicker.getFullYear();
            const month = this.startDatePicker.getMonth() + 1;
            const day = this.startDatePicker.getDate().toString();
            this.startDate = year + '-' + month + '-' + day;
            this.blockPosition = true;
            this.startDateLabel = this.getDateFormat(day, month, year);
            this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.currentCustomerToken, false);
        } else {
            this.startDate = null;
            this.startDateLabel = 'Date de début';
        }
    }

    private getCount(count: number): number {
        if (count > 99) {
            return 99;
        } else {
            return count;
        }
    }

    public updateEndDate(dateObject) {
        if (dateObject.value != null) {
            this.endDatePicker = new Date(dateObject.value);
            const year = this.endDatePicker.getFullYear();
            const month = this.endDatePicker.getMonth() + 1;
            const day = this.endDatePicker.getDate().toString();
            this.endDate = year + '-' + month + '-' + day;
            this.blockPosition = true;
            this.endDateLabel = this.getDateFormat(day, month, year);
            this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.currentCustomerToken, false);
        } else {
            this.endDate = null;
            this.endDateLabel = 'Date de fin';
        }
    }

    private getDateFormat(day, month, year: number): string {
        let dateToReturn = '';
        if (day < 10) {
            dateToReturn = '0';
        }
        dateToReturn += day + '/';
        if (month < 10) {
            dateToReturn += '0';
        }
        dateToReturn += month + '/' + year;
        return dateToReturn;
    }

    public checkDeleteFilters(pickerStart: MatDatepicker<any>, pickerEnd: MatDatepicker<any>) {
        this.modalService.confirmModal('Attention', 'Voulez-vous vraiment réinitialiser les filtres ?', args => {
            this.deleteFilters(pickerStart, pickerEnd);
        });
    }

    public deleteFilters(pickerStart: MatDatepicker<any>, pickerEnd: MatDatepicker<any>) {
        this.blockPosition = false;
        this.startDate = null;
        this.endDate = null;
        this.endDateLabel = 'Date de fin';
        this.startDateLabel = 'Date de début';
        pickerStart.select(null);
        pickerEnd.select(null);
        // triPrio.writeValue(null);
        // triAnciennete.writeValue(null);
        this.displayModePriority = '';
        this.displayModeDate = '';
        this.priorityOrder = 'no';
        this.sortingOrder = 'no';
        this.sortByPriority = null;
        this.sortByAddDate = null;
        this.showArchivedArticles = null;
        this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.currentCustomerToken, false);
        this.tabCollaboratorsSelected = [];
        this.collaboratorsForm.reset();
    }

    public onShowArchivedArticlesChange(event) {
        this.showArchivedArticles = event;
        this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.currentCustomerToken, false);
    }

    public sortingPriority(priority: string) {
        if (priority === 'no') {
            this.displayModePriority = '';
            this.sortByPriority = null;
        } else if (priority === 'ascending') {
            this.displayModePriority = 'Croissante';
            this.blockPosition = true;
            this.sortByPriority = true;
        } else if (priority === 'descending') {
            this.displayModePriority = 'Décroissante';
            this.blockPosition = true;
            this.sortByPriority = false;
        }
        this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.currentCustomerToken, false);
    }

    public sortingAddDate(date: string) {
        if (date === 'no') {
            this.sortByAddDate = null;
            this.displayModeDate = '';
        } else if (date === 'ascending') {
            this.displayModeDate = 'Croissante';
            this.sortByAddDate = true;
            this.blockPosition = true;
        } else if (date === 'descending') {
            this.displayModeDate = 'Décroissante';
            this.blockPosition = true;
            this.sortByAddDate = false;
        }
        this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.currentCustomerToken, false);
    }

    public onCollaboratorSelect(event) {
        if (event.isUserInput === true) {
            const indexID: number = this.tabCollaboratorsSelected.indexOf(event.source.value.idCollaborator);
            if (indexID === -1) {
                this.tabCollaboratorsSelected.push(event.source.value.idCollaborator);
            } else {
                this.tabCollaboratorsSelected.splice(indexID, 1);
            }
        }
    }

    private inputFocusProcess(matFormField: ElementRef): void {
        if (matFormField && !this.isInputFocused) {
            this.isInputFocused = true;
        }

        if (!matFormField && this.isInputFocused) {
            this.isInputFocused = false;

            setTimeout(
                () => {
                    //
                    // this.refreshData();
                    //
                });
        }
    }

    visualiseOtherKanban(customer) {
        this.displayAKanban = customer.name;
        if (customer.user_id !== this.customerToken) {
            this.isVisualisation = true;
            this.currentCustomerToken = customer.user_id;
        } else {
            this.isVisualisation = false;
            this.currentCustomerToken = this.customerToken;
        }
        // Compte de Juliette / Lou-Anne / Lou pour PREVOST : superviseur qui peut écrire dans les Kanbans des autres
        if (this.customerToken === '0031v00002s6oWaAAI'
            || this.customerToken === '003AM000003aN5bYAE'
            || this.customerToken === '003IV00000NwiTIYAZ'
            || this.customerToken === '003IV00000p0fAoYAI'
        ) {
            this.isVisualisation = false;
        }
        this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.currentCustomerToken, this.isVisualisation);
    }

    public async openLeadCreationModal(): Promise<void> {
        this.modalService.showCustomModalWithActionOnClose(CreateLeadModalComponent, {
            data: {
                modification: false,
            }}, (data) => {
                this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.customerToken, this.isVisualisation);
            }
        );
    }

    protected readonly IconType = IconType;

    protected onDropDownMenuOpenAffichage($event): void {
        $event.stopPropagation();
        this.menuOpenAffichage = !this.menuOpenAffichage;
        if (this.menuOpenAffichage) {
            this.menuIconAffichage = 'keyboard_arrow_up';
        }
    }

    protected onMenuAffichageClosed(): void {
        this.menuOpenAffichage = false;
        this.menuIconAffichage = 'keyboard_arrow_down';
    }

    protected onDropDownMenuOpenDate($event): void {
        $event.stopPropagation();
        this.menuOpenDate = !this.menuOpenDate;
        if (this.menuOpenDate) {
            this.menuIconDate = 'keyboard_arrow_up';
        }
    }

    protected onMenuDateClosed(): void {
        this.menuOpenDate = false;
        this.menuIconDate = 'keyboard_arrow_down';
    }

    protected onDropDownMenuOpenKanban($event): void {
        $event.stopPropagation();
        this.menuOpenKanban = !this.menuOpenKanban;
        if (this.menuOpenKanban) {
            this.menuIconKanban = 'keyboard_arrow_up';
        }
    }

    protected onMenuKanbanClosed(): void {
        this.menuOpenKanban = false;
        this.menuIconKanban = 'keyboard_arrow_down';
    }

    protected readonly ColorType = ColorType;
}
